.blog-image {
  width: 100%;
  height: 160px !important;
  object-fit: cover;
}

.blog-title {
  height: 100px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 28.13px;
  color: #343434;
  cursor: pointer;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.4;
}

.blog-title:hover {
  color: #407933;
}

.btn-blog-primary {
  background-color: #407933 !important;
  border: 2px solid #407933 !important;
  display: inline-block !important;
  color: #ffffff !important;
  padding: 15px 25px !important;
  border-radius: 2px !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  font-style: normal !important;
  line-height: 19px !important;
  max-height: unset !important;
}

.share-btn {
  box-shadow: none !important;
  background: #ff912e;
  display: inline-block !important;
  color: #ffffff !important;
  padding: 15px 25px !important;
  border-radius: 2px !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  font-style: normal !important;
  line-height: 19px !important;
  max-height: unset !important;
}

.share-btn:hover {
  background: #ff912e;
  border: 2px solid white;
  color: white;
}

.pagination .page-item.active .page-link {
  background-color: #4caf50;
  border-color: #4caf50;
  color: white;
}

.pagination .page-item .page-link {
  color: #4caf50;
}

.pagination .page-item:hover .page-link {
  background-color: #4caf50;
  color: white;
}

.pagination-details {
  text-align: center;
  margin: 40px 0 0 0;
}
.post-title {
  color: #3d3b3b;
  width: 100%;
  padding: 0;
  position: relative;
  text-shadow: none;
}
.blog-author {
  color: #8c8d8d;
  font-size: 13px;
  font-family: "Roboto", sans-serif;
}
.blog-main-img{
  width: 600px;
  height: 600px;
}

@media (max-width: 768px) {
  .main-blog-container{
    margin-top: 4rem !important;
  }
  .blog-main-img{
    width: 300px;
    height: 300px;
    margin-top: 50px;
  }
}

