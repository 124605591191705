.product-title {
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
}

.product-description {
  font-size: 14px;
  margin: 0px auto 0px auto;
  line-height: 16px;
  font-weight: 400;
  width: 50%;
  text-align: center;
  height: 80px;
  display: flex;
  justify-content: center;
}

.products-description {
  max-width: 800px;

  line-height: 1.6;
  font-size: 14px;
  color: #666;
}

.best-for {
  color: #3b9c2e;
  font-weight: 400;
  margin-bottom: 20px;
}

.advantages-benefits {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
  color: #666666;
}

.advantages-benefits-list {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: #666666;
  /* text-align: left; */
  list-style-type: disc;
  margin-left: 20px;
}

.testimonial {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.25);

  padding: 10px;
  border-radius: 4px;
  text-align: center;
  margin-bottom: 30px;
}

.price-section {
  background-color: #ffc107;
  padding: 5px 10px 5px 10px;
}

.price {
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  color: #333333;
  font-weight: 600;
}



.no-hover-effect:hover {
  background-color: #0b6938;
  color: white;
  border-color: none;
}

.buy-now-btn {
  background-color: #0b6938;
  color: white;
  border: none;
  border-radius: 9.59px 0px 9.55px 0px;
  padding: 10.55px 9.59px 10.55px 9.55px;
  cursor: pointer;
}

.product-image {
  width: 95%;
  height: 320px;
}
.product-images {
  width: 100%;
  height: 300px;
}

.stars {
  color: #ffc107;
  font-size: 24px;
}

/* .extra-details {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  line-height: 1.6;

  color: #4a4a4a;
} */

.explore {
  top: 193.85px;
  left: 46.37px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 13.43px;
  cursor: pointer;
  color: #3b9c2e;
}

.scrolling-wrapper {
  overflow-x: auto;
  white-space: nowrap;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.scrolling-wrapper::-webkit-scrollbar {
  display: none;
}

.sticky-row {
  position: sticky;
  top: 0;
  z-index: 1000;
  padding: 10px 0;
}

.categories {
  border-radius: 10px;
  padding: 0;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f9f9f9;
  transition: box-shadow 0.3s ease;
  margin-bottom: 5px;
  overflow: hidden;
}

.category-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.categories:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.category-title {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  margin-top: 5px;
  text-align: center;
}
.share-media {
  color: #0b6938;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
}

.share-input-box {
  padding: 8px;
  border: 1px solid #8080802e;
  border-radius: 5px;
}
.share-input-box:hover {
  background-color: transparent;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  font-weight: 600;
  border: 1px solid #1859ba;
}

.btn-share {
  box-shadow: none !important;
  background: #ff912e;
  color: white;
  border: 2px solid white;
}
.btn-share:hover {
  background: #ff912e;
  border: 2px solid white;
  color: white;
}

.btn-outline-white {
  outline: 1px solid white !important;
}

.product-image-box {
  width: 90%;
  height: 427px;
  /* border: 0.1px solid rgb(137, 132, 132); */
  display: flex; 
  align-items: center; 
  justify-content: center; 
  overflow: hidden; 
}

.products-img {
  object-fit: contain;
  max-width: 100%; 
  max-height: 100%; 
}

@media (max-width: 768px) {
  .testimonial {
    margin-bottom: 30px;
  }
  .products-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .col-12 {
    flex: 0 0 auto;
    width: 55%;
  }

  .category-title {
    font-size: 18px;
    font-weight: bold;
    color: #333;
    margin-top: 5px;
    text-align: center;
    margin-bottom: 20px;
  }

  .product-cards {
    justify-content: flex-start !important;
  }

  
  

  /* .extra-details {
    order: 2;
    text-align: center;
  } */

  .share-container {
    order: 1;
    margin-top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .products-description {
    text-align: center;
    line-height: 1.6;
    font-size: 14px;
    color: #666;
  }
  .product-title {
    text-align: center;
  }

  .razorpay-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .product-description {
    font-size: 14px;
    margin: 0px auto 0px auto;
    line-height: 16px;
    font-weight: 400;
    width: 100%;
    text-align: center;
    height: 50%;
    display: flex;
    /* align-items: center; */
    justify-content: center;
  }
}
.custom-modal-content .modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 70%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
  margin-left: 26%;
}
/* CSS */
@media (max-width: 768px) { 
  /* .extra-details {
    max-height: 150px; 
    overflow-y: auto;
  } */

  .products-description {
    max-height: 300px;
    overflow-y: auto;
    scrollbar-width: none; 
  }
  .products-description::-webkit-scrollbar {
    display: none; 
  }
  
  
}
/* .scrollable {
  overflow-y: auto;
  max-height: 150px;
} */

.products-description {
  max-height: 150px; 
  overflow-y: auto;
  scrollbar-width: none; 
}
.products-description::-webkit-scrollbar {
  display: none;
}

.copy-text{
  position: absolute;
  bottom: -28px;
  margin-right: 7%;
  color: #0b6938;
  font-size: 12px;
transition: opacity 0.3s ease;
}

@media (max-width: 768px) {
  .product-image-box {
    width: 100%;
    height: 200px;
    border: none;
    display: flex; 
    align-items: center; 
    justify-content: center; 
    overflow: hidden; 
  }

  .products-img {
    width: 100%;
    height: auto;
    padding: 5%;
  }
}

/* .product-cards{
  justify-content: center !important;
} */