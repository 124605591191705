.header-carousel img {
  height: 700px;
  object-fit: inherit;
  width: 100%;
}

.carousel-indicators [data-bs-target] {
  background-color: #8bc34a;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin: 0 5px;
}

.carousel-indicators [data-bs-target].active {
  background-color: #0b6938;
  width: 30px;
  height: 16px;
  border-radius: 100px;
  transition: width 0.3s ease;
}

.explore-button {
  background-color: #0b6938;
  color: #fff;
}
.main-container-image {
  object-fit: inherit;
  margin-top: 80px;
  width: 100%;
  height: 700px;
}



.carousel-caption {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-overlay {
  width: 372px;
  height: auto;
  background-color: #ffffff;
  opacity: 70%;
  padding: 24px 36px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  position: absolute;
  top: 50%;
  left: 78%;
  transform: translate(-50%, -50%);
}

.card-overlays {
  width: 372px;
  height: auto;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 24px 36px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  position: absolute;
  top: 55%;
  left: 78%;
  transform: translate(-50%, -50%);
}

.card-title {
  /* width: 293px; */
  height: 90px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 28.13px;
  color: #0b6938;
}

.card-heading {
  width: 293px;
  height: 90px;
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  font-size: 38px;
  line-height: 56.25px;
  color: #0b6938;
}

.card-text {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  color: #0b6938;
}

button.btn-success {
  background-color: #28a745;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  text-transform: uppercase;
}

button.btn-success:hover {
  background-color: #218838;
}

@media (max-width: 768px) {
  .card-overlay {
    width: 80%;
    padding: 20px;
    left: 50%;
  }

  .card-title {
    font-size: 20px;

    height: 70px;
    font-weight: 500;
    font-family: "Inter";
    line-height: 23.44px;
    color: #0b6938;
  }

  .card-heading {
    height: 70px;
    font-family: "Roboto", sans-serif;
    font-weight: 900;
    font-size: 36px;
    line-height: 42.16px;
    color: #0b6938;
  }

  .card-text {
    font-size: 14px;
    font-weight: 500;
    font-family: "Inter";
    line-height: 16px;
    color: #0b6938;
  }

  button.btn-success {
    font-size: 14px;
    padding: 8px 16px;
  }
  .card-overlay,
  .card-overlays {
    display: none;
  }

  .main-container-image {
    height: 60vh;
    object-fit: cover;
    margin-top: 25px;
  }
  .header-carousel img {
    width: 100%;
    height: 175px;
    top: 75px;
  }
  .header-carousel {
    margin-top: 76px;
  }
  .card-body {
    --bs-card-border-color: none;
    /* margin-bottom: 135px; */
  }
  .card {
    --bs-card-border-color: none;
  }
.main-card-body{
       margin-bottom: 135px; 

}
  .carousel-indicators {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
  }

  .carousel-indicators li {
    background-color: #000;
    width: 10px;
    height: 10px;
    border-radius: 50%;
  }

  .carousel-indicators .active {
    background-color: #4caf50;
  }
  .mobile-view-flow{
    margin: 0 !important;
  }
  .main-slider-crd{
    height: 400px !important;
  }

  .explore-button {
    width: 97px;
    height: 42px;
    margin-top: 247px;
    border-radius: 4px;
   padding: 10px;
    gap: 10px;
  }
  .main-container {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .carousel-control-next,
  .carousel-control-prev {
    position: absolute;
    bottom: 375px;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15%;
    padding: 0;
    color: #fff;
    text-align: center;
    background: 0 0;
    border: 0;
    opacity: 0.5;
    transition: opacity 0.15s ease;
  }

  .carousel-indicators [data-bs-target] {
    background-color: #90bc1b;
    width: 10px;
    height: 10px;
    border: 1px;
    
  }

  .carousel-indicators [data-bs-target].active {
    background-color: #0b6938;
    width: 20px;
    height: 10px;
    border-radius: 100px;
    transition: width 0.3s ease;
    border: 1px;
  }
  
}

.corner-image {
  width: 186px;
  height: 400px;
  position: absolute;
  top: 187px;
  left: 13spx;
  z-index: 1;
}

.main-container {
  padding-left: 0px;
  padding-right: 0px;
}
